import { ListItem, ListContainer } from "../components/assignment/types"
import { ProjectData, UserData, WBSData } from "../state/types"

export const mapWbsToListItem = (wbs: WBSData): ListItem => ({ id: wbs.id, value: `${wbs.name} (${wbs.code})`, active: wbs.active })

export const mapProjectToListContainer = (project: ProjectData): ListContainer =>
    ({ id: project.id, name: project.name, items: project.WBS.map(mapWbsToListItem), active: project.active })

export const mapUserToListItem = (user: UserData): ListItem => ({ id: user.id, value: user.displayName })

export const mapWbsToListContainer = (wbsList: WBSData[], allProjects: ProjectData[]): ListContainer[] => {
    var output: ListContainer[] = []

    wbsList.forEach(wbs => {
        const project = allProjects.find(p => p.WBS.find(w => w.id === wbs.id))
        const outputContainer = output.find(c => c.id === project!.id)
        if (outputContainer) {
            outputContainer.items = [...outputContainer.items, mapWbsToListItem(wbs)].sort((i1, i2) => i1.value.localeCompare(i2.value))
        } else {
            output = [...output, { id: project!.id, name: project!.name, items: [mapWbsToListItem(wbs)] }].sort((i1, i2) => i1.name.localeCompare(i2.name))
        }
    })

    return output
}