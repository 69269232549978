import { FC } from "react";
import { Button, Stack, TextField } from '@mui/material';
import { ProjectData } from "../../../../../state/types";
import { Formik, Form, Field } from 'formik';
import { COMMON_LABELS_TRANSLATIONS, ERROR_MESSAGE_TRANSLATIONS, WBS_PROJECTEDITOR_TRANSLATIONS } from "../../../translations";
import { useAppTranslationByLocation } from "../../../../../translations";
import { ProjectDataForm, ProjectEditorProps } from "../types";
import { ActiveSwitch } from "./ActiveSwitch";
import { TFunction } from "i18next";
import * as Yup from 'yup';

export const ProjectEditor: FC<ProjectEditorProps> = ({ project, onProjectFormSubmitted }) => {
    const { t } = useAppTranslationByLocation();

    const onSubmit = (newProjectFromForm: ProjectDataForm) => {
        const newProject: ProjectData = {
            id: newProjectFromForm?.id ?? 0,
            name: newProjectFromForm.name!,
            active: newProjectFromForm.active,
            WBS: project?.WBS ?? []
        }

        onProjectFormSubmitted(newProject)
    };

    return (
        <Formik initialValues={mapToInitialValue(project)} onSubmit={onSubmit} enableReinitialize validationSchema={buildProjectValidationSchema(t)}>
            {({ touched, errors }) => (
                <Form>
                    <Stack spacing={2}>
                        <h2 style={{ marginLeft: 14 }}>{project != null ? `${t(COMMON_LABELS_TRANSLATIONS.EDIT)} ${project.name}` : `${t(WBS_PROJECTEDITOR_TRANSLATIONS.EDITOR_LABELS.ADD_NEW_PROJECTS)}`}</h2>
                        {touched.name && errors.name && <p style={{ color: "#FF0000" }}>{errors.name}</p>}
                        <Field name="name" as={TextField} label={t(COMMON_LABELS_TRANSLATIONS.NAME)} />
                        {touched.name && errors.name && <p style={{ color: "#FF0000" }}>{errors.active}</p>}
                        <Field name="active" as={ActiveSwitch} />
                        <Button variant="contained" type="submit">{project != null ? `${t(COMMON_LABELS_TRANSLATIONS.UPDATE)} ${t(COMMON_LABELS_TRANSLATIONS.PROJECT)}` : `${t(COMMON_LABELS_TRANSLATIONS.CREATE)} ${t(COMMON_LABELS_TRANSLATIONS.PROJECT)}`}</Button>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
}

const mapToInitialValue = (project?: ProjectData): ProjectDataForm => {
    return {
        id: project?.id ?? 0,
        name: project?.name ?? "",
        active: project?.active ?? true
    }
}

const buildProjectValidationSchema = (t: TFunction<"hat-web", undefined, "hat-web">) => {
    const projectFormValidationSchema: Yup.Schema<ProjectDataForm> = Yup.object().shape({
        name: Yup.string()
            .min(3, `${t(COMMON_LABELS_TRANSLATIONS.NAME)} ${t(ERROR_MESSAGE_TRANSLATIONS.IS_TOO_SHORT)}`)
            .max(255, `${t(COMMON_LABELS_TRANSLATIONS.NAME)} ${t(ERROR_MESSAGE_TRANSLATIONS.IS_TOO_LONG)}`)
            .required(`${t(COMMON_LABELS_TRANSLATIONS.NAME)} ${t(ERROR_MESSAGE_TRANSLATIONS.IS_REQUIRED)}`),
        active: Yup.bool()
            .required()
    });

    return projectFormValidationSchema
}