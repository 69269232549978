import { useState, FC, useEffect } from 'react';
import { Box, Button, Stack } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { UserData } from '../../../../state/types';
import { useDispatch, useSelector } from 'react-redux';
import {
    createUserViaSagaAction,
    updateUserViaSagaAction,
    loadAllProjectDataViaSagaAction,
    loadWbsForUserViaSagaAction,
    saveWbsForUserViaSagaAction,
    loadAllUsersViaSagaAction
} from '../../../../state/sagas/actions';
import { allProjectsSelector, allUsersSelector, userWbsSelector } from '../../../../state/selectors';
import { UserEditModal, UserInfoDisplay } from './components';
import { Assignment, IsActiveSwitch, FilteredList } from '../../../../components';
import { ListItem } from '../../../../components/assignment/types';
import { useAppTranslationByLocation } from '../../../../translations';
import { USER_EDITOR_LABELS } from './translations';
import { Item } from '../../../../components/styling';
import { filterWbs } from '../../../../utils';
import { mapProjectToListContainer, mapUserToListItem, mapWbsToListContainer } from '../../../../utils/filteredListHelpers';

export const UserEditorView: FC = () => {
    const [selectedUser, setSelectedUser] = useState<UserData | undefined>(undefined);
    const [userModalOpen, setUserModalOpen] = useState<boolean>(false)
    const [showActiveUsers, setShowActiveUsers] = useState<boolean>(true)

    const { t } = useAppTranslationByLocation();

    const dispatch = useDispatch()
    const allUsers = useSelector(allUsersSelector)
    const allProjects = useSelector(allProjectsSelector)
    const userWbs = useSelector(userWbsSelector)

    useEffect(() => {
        dispatch(loadAllProjectDataViaSagaAction())
        dispatch(loadAllUsersViaSagaAction());
    }, [dispatch])

    const handleUserModalClose = () => setUserModalOpen(false)

    const handleSelectUser = (item: ListItem) => {
        setSelectedUser(allUsers.find(u => u.id === item.id));
        //load WBS for user
        dispatch(loadWbsForUserViaSagaAction({ userId: item.id }))
    }

    const handleUserFormSubmitted = (user: UserData) => {
        if (user.id === 0) {
            dispatch(createUserViaSagaAction({ user }))
        } else {
            dispatch(updateUserViaSagaAction(user))
            setSelectedUser(user)
        }
        setUserModalOpen(false)
    }

    const handleNewUserClick = () => {
        setSelectedUser(undefined)
        setUserModalOpen(true)
    }

    const handleEditUserClick = () => setUserModalOpen(true)

    function handleAssignmentSave(items: ListItem[]): void {
        dispatch(saveWbsForUserViaSagaAction({ userId: selectedUser!.id, wbsIds: items.map(i => i.id) }))
    }

    const handleActiveSwitchClick = () => {
        setSelectedUser(undefined)
        setShowActiveUsers(!showActiveUsers)
    }

    return (
        <>
            <Grid container spacing={2} padding={1} width={'100%'}>
                <Grid xs={4}>
                    <Item style={{ height: '100%' }}>
                        <Stack spacing={1} sx={{ height: "100%" }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <FilteredList
                                    listItems={allUsers.filter(u => u.isActive === showActiveUsers).map(u => ({ id: u.id, value: u.displayName }))}
                                    selectedItem={selectedUser && mapUserToListItem(selectedUser)}
                                    onItemSelected={handleSelectUser} />
                            </Box>
                            <IsActiveSwitch
                                label={t(USER_EDITOR_LABELS.ACTIVE_USERS)}
                                showActive={showActiveUsers}
                                onChange={handleActiveSwitchClick}
                            />
                            <Button variant="contained" fullWidth onClick={handleNewUserClick}>{t(USER_EDITOR_LABELS.CREATE_NEW_USER)}</Button>
                        </Stack>
                    </Item>
                </Grid>
                <Grid xs={8}>
                    <Item style={{ height: '100%' }}>
                        {selectedUser &&
                            <Stack spacing={1} direction={'column'} sx={{ height: '100%' }}>
                                <UserInfoDisplay user={selectedUser} onEditIconClick={handleEditUserClick} />
                                <Assignment
                                    allContainerItems={filterWbs(allProjects, true).map(mapProjectToListContainer)}
                                    selectedContainerItems={mapWbsToListContainer(userWbs, allProjects)}
                                    onItemsSelectionFinished={handleAssignmentSave} />
                            </Stack>
                        }
                    </Item>
                </Grid>
            </Grid>
            <UserEditModal open={userModalOpen} onClose={handleUserModalClose} user={selectedUser} onUserFormSubmitted={handleUserFormSubmitted} />
        </>
    );
};