import { FormControlLabel, FormGroup, Switch } from "@mui/material"
import { FC } from "react"

type IsActiveSwitchProps = {
    label: string,
    showActive: boolean,
    onChange: () => void,
}

export const IsActiveSwitch: FC<IsActiveSwitchProps> = ({ label, showActive, onChange }) => {
    return (
        <FormGroup row>
            <FormControlLabel
                label={label}
                control={<Switch checked={showActive} onChange={onChange} />}
                labelPlacement='start' />
        </FormGroup>
    )
}