import { Paper, Stack, Typography } from "@mui/material"
import { WBSData } from "../../../../../state/types"
import { EditIcon } from "../../../../../components/formElements/EditIcon"
import { useSelector } from "react-redux"
import { allProjectsSelector } from "../../../../../state/selectors"

export type WbsInfoDisplayProps = {
    wbs: WBSData,
    onEditIconClick: () => void
}

export const WbsInfoDisplay: React.FC<WbsInfoDisplayProps> = ({ wbs, onEditIconClick }) => {
    const projects = useSelector(allProjectsSelector)

    return (
        <Paper variant="outlined" sx={{ width: '100%' }}>
            <Stack direction={'row'} spacing={2} justifyContent={'space-between'} padding={1}>
                <Stack direction={'column'} justifyContent={'flex-end'} spacing={1}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{wbs.name}</Typography>
                    <Typography variant="body2">{`Code: ${wbs.code}`}</Typography>
                </Stack>
                <Stack direction={'column'} justifyContent={'flex-end'} spacing={1}>
                    <Typography variant="body2">{`Project: ${projects.find(p => p.id === wbs.projectId)?.name}`}</Typography>
                    <Typography variant="body2">{`Is active: ${wbs.active}`}</Typography>
                </Stack>
                <EditIcon size="large" onClick={onEditIconClick} />
            </Stack>
        </Paper>
    )
}