import { Button, Stack, Snackbar, Alert, AlertColor, Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { ProjectData, WBSData } from '../../../../state/types';
import { WBS_PROJECTEDITOR_TRANSLATIONS } from '../../translations';
import { useAppTranslationByLocation } from '../../../../translations';
import { useDispatch, useSelector } from 'react-redux';
import { HATState } from '../../../../state';
import * as actions from '../../../../state/sagas/actions';
import { usersForWbsSavedAction } from '../../../../state/redux/actions';
import { ProjectEditModal } from './components/ProjectEditModal';
import { WbsEditModal } from './components/WbsEditModal';
import { Assignment, FilteredList, IsActiveSwitch } from '../../../../components';
import { ListContainer, ListItem } from '../../../../components/assignment/types';
import { allProjectsSelector, allActiveUsersSelector, activeUsersForWbsSelector } from '../../../../state/selectors';
import { ResponseStatus } from '../../../../services/types';
import { Item } from '../../../../components/styling';
import { filterWbs } from '../../../../utils';
import { WbsInfoDisplay } from './components/WbsInfoDisplay';
import { mapProjectToListContainer, mapUserToListItem, mapWbsToListItem } from '../../../../utils/filteredListHelpers';

type AlertData = {
    message: string,
    severity: AlertColor
}

export const WBSProjectEditorView = () => {
    const { t } = useAppTranslationByLocation();
    const dispatch = useDispatch()

    const [selectedProject, setSelectedProject] = useState<ProjectData | undefined>()
    const [selectedWbs, setSelectedWbs] = useState<WBSData | undefined>()
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false)
    const [alertData, setAlertData] = useState<AlertData>({ message: '', severity: 'success' })
    const [projectModalOpen, setProjectModalOpen] = useState<boolean>(false)
    const [wbsModalOpen, setWbsModalOpen] = useState<boolean>(false)
    const [showActiveElements, setShowActiveElements] = useState<boolean>(true)

    const projects = useSelector(allProjectsSelector)
    const wbsDeleteResult = useSelector((state: HATState) => state.notifications.wbsDeleteResult)
    const usersForWbsSavedResult = useSelector((state: HATState) => state.notifications.usersForWbsSaved)
    const allUsers = useSelector(allActiveUsersSelector)
    const usersForWbs = useSelector(activeUsersForWbsSelector)

    useEffect(() => {
        dispatch(actions.loadAllProjectDataViaSagaAction());
        dispatch(actions.loadAllUsersViaSagaAction())
    }, [dispatch])

    useEffect(() => {
        switch (wbsDeleteResult) {
            case ResponseStatus.OK: { showAlert(t(WBS_PROJECTEDITOR_TRANSLATIONS.WBS_DELETED), 'success'); break; }
            case ResponseStatus.WARNING: { showAlert(t(WBS_PROJECTEDITOR_TRANSLATIONS.WBS_DELETE_WARNING), 'warning'); break; }
            case ResponseStatus.ERROR: { showAlert(t(WBS_PROJECTEDITOR_TRANSLATIONS.INTERNAL_ERROR), 'error'); break; }
        }
    }, [wbsDeleteResult, dispatch, t])

    useEffect(() => {
        switch (usersForWbsSavedResult) {
            case ResponseStatus.OK: { showAlert(t(WBS_PROJECTEDITOR_TRANSLATIONS.USERS_SAVED), 'success'); break; }
            case ResponseStatus.ERROR: { showAlert(t(WBS_PROJECTEDITOR_TRANSLATIONS.INTERNAL_ERROR), 'error'); break; }
            default: return
        }
        dispatch(usersForWbsSavedAction(undefined)) //erase the number after processing
    }, [usersForWbsSavedResult, dispatch, t])

    const handleNewProjectClick = () => {
        setSelectedProject(undefined)
        setProjectModalOpen(true)
    }

    const handleNewWbsClick = () => {
        setSelectedWbs(undefined)
        setWbsModalOpen(true)
    }

    const handleWbsModalOpen = () => setWbsModalOpen(true)
    const handleProjectModalClose = () => setProjectModalOpen(false)
    const handleWbsModalClose = () => setWbsModalOpen(false)

    const handleWbsFormSubmitted = (wbs: WBSData) => {
        //validate if there is wbs with the same code & name
        const wbsList = projects.reduce((result: WBSData[], p) => result.concat(p.WBS), [])
        if (wbsList.filter(w => w.code === wbs.code).filter(w => w.name === wbs.name).filter(w => w.id !== wbs.id).length > 0) {
            showAlert(t(WBS_PROJECTEDITOR_TRANSLATIONS.DUPLICATE_WBS), 'warning')
        }
        else {
            dispatch(actions.saveWBSViaSagaAction(wbs))
            handleWbsModalClose()
            showAlert(t(WBS_PROJECTEDITOR_TRANSLATIONS.WBS_SAVED), 'success')
        }
    }

    const handleWbsDeleteClick = (wbs: WBSData) => {
        handleWbsModalClose()
        dispatch(actions.deleteWbsViaSagaAction({ id: wbs.id }))
    }

    const handleProjectFormSubmitted = (project: ProjectData) => {
        dispatch(actions.createOrUpdateProjectViaSagaAction(project))
        handleProjectModalClose()
        showAlert(t(WBS_PROJECTEDITOR_TRANSLATIONS.PROJECT_SAVED), 'success')
    }

    const showAlert = (message: string, severity: AlertColor) => {
        setAlertData({ message, severity })
        setSnackbarOpen(true)
    }

    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }

    const handleAssignmentSave = (items: ListItem[]) => {
        dispatch(actions.saveUsersForWbsViaSagaAction({ wbsId: selectedWbs!.id, userIds: items.map(i => i.id) }))
    }

    const handleActiveSwitchClick = () => {
        setShowActiveElements(!showActiveElements)
        if (!selectedWbs?.active) setSelectedWbs(undefined)
    }

    const handleWbsSelected = (item: ListItem) => {
        //find the WBS object in all project data
        var project = projects.find(p => p.WBS.find(w => w.id === item.id))!
        var wbs = project.WBS.find(w => w.id === item.id)!

        setSelectedWbs(wbs)
        dispatch(actions.loadUsersForWbsSagaAction({ wbsId: wbs.id }))
    }

    const handleProjectSelected = (container: ListContainer) => {
        //find the project in all project data
        setSelectedProject(projects.find(p => p.id === container.id)!)
        setProjectModalOpen(true)
    }

    return (
        <>
            <Grid container spacing={2} padding={1} width={'100%'}>
                <Grid xs={4}>
                    <Item style={{ height: '100%' }}>
                        <Stack spacing={1} height={'100%'}>

                            <Box sx={{ flexGrow: 1 }}>
                                <FilteredList
                                    containerItems={showActiveElements ? filterWbs(projects, showActiveElements).map(mapProjectToListContainer) : projects.map(mapProjectToListContainer)}
                                    selectedItem={selectedWbs && mapWbsToListItem(selectedWbs)}
                                    onItemSelected={handleWbsSelected}
                                    onContainerSelected={handleProjectSelected} />
                            </Box>
                            <IsActiveSwitch
                                label='Show active'
                                showActive={showActiveElements}
                                onChange={handleActiveSwitchClick}
                            />
                            <Button variant="contained" fullWidth onClick={handleNewProjectClick}>{t(WBS_PROJECTEDITOR_TRANSLATIONS.CREATE_NEW_PROJECT_LABEL)}</Button>
                            <Button variant="contained" fullWidth onClick={handleNewWbsClick}>{t(WBS_PROJECTEDITOR_TRANSLATIONS.CREATE_NEW_WBS_LABEL)}</Button>
                        </Stack>
                    </Item>
                </Grid>
                <Grid xs={8}>
                    <Item style={{ height: '100%' }}>
                        {selectedWbs &&
                            <Stack spacing={1} direction={'column'} sx={{ height: '100%' }}>
                                <WbsInfoDisplay wbs={selectedWbs} onEditIconClick={handleWbsModalOpen} />
                                <Assignment
                                    allItems={allUsers.map(u => mapUserToListItem(u))}
                                    selectedItems={usersForWbs.map(u => mapUserToListItem(u))}
                                    onItemsSelectionFinished={handleAssignmentSave} />
                            </Stack>
                        }
                    </Item>
                </Grid>
            </Grid >
            <Snackbar open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                sx={{ pt: 10 }}>
                <Alert onClose={handleSnackbarClose} severity={alertData.severity} sx={{ width: '100%' }} elevation={6} variant="filled">
                    {alertData.message}
                </Alert>
            </Snackbar>
            <ProjectEditModal open={projectModalOpen} onClose={handleProjectModalClose} project={selectedProject} onProjectFormSubmitted={handleProjectFormSubmitted} />
            <WbsEditModal open={wbsModalOpen} onClose={handleWbsModalClose} wbs={selectedWbs} onWbsFormSubmitted={handleWbsFormSubmitted} onWbsDeleteClicked={handleWbsDeleteClick} />
        </>
    );
};